import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./privacy_policy.css"

const PrivacyPolicy = () => {
  return (
    <Layout page="privacy-policy">
      <SEO title="Privacy Policy" description="NeonCoat.com's Privacy Policy."/>
      <div className="flex-grid">
        <div className="col" />
        <div className="privacy-policy-container">
          <div className="privacy-policy-title">Privacy Policy</div>
          <div className="privacy-policy-text">
            Neon Coat, LLC Privacy Policy
            <br />
            <b>Last Updated: March 28, 2017</b>
            <br />
            This Privacy Policy explains how Neon Coat, LLC (“Neon Coat,” or
            “we” or “us”) collects,uses and shares information about you when
            you use our website, mobile application and other online products
            and services (collectively, the “Services”) or when you otherwise
            interact with Us.
            <p>
              We reserve the right to change these Terms from time to time. If
              we make changes, we will notify you by revising the date at the
              top of this Policy (and, in some cases, providing you with
              additional notice such as adding a statement to our homepage or
              sending you an email notification). All users of Neon Coat
              Services should review this Policy periodically to ensure they
              stay informed of any updates. Changes to these Terms will be
              effective when posted (unless you are notified otherwise), and
              your continued use of Neon Coat Services after the effective date
              of any such revisions will signify your acceptance of those
              changes. If you have any questions about this Privacy Policy,
              please contact us at
              <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.
            </p>
            <p>
              COLLECTING INFORMATION
              <br /> <b>Information You Provide to Us</b>
              <ul>
                <li>
                  Account Information: To create an account with Neon Coat, you
                  may be required to provide personal information about
                  yourself, including but not limited to your email address,
                  date of birth, name, gender, address, social media profiles,
                  passwords and billing Information.
                </li>
                <li>
                  Other Information: You may voluntarily provide other
                  information directly to us that is not required to use the
                  Services. For example, we collect information when you fill
                  out a form, complete a survey or review, participate in a
                  contest or promotion, apply for a job, communicate with us via
                  third-party social media sites, request customer support or
                  otherwise communicate with us.
                </li>
              </ul>
            </p>
            <p>
              <b>
                Information We Collect Automatically When You Use the Services
              </b>
              <br />
              When you access or use our Services, we may automatically collect
              information about you, including:
              <ul>
                <li>
                  Usage Information: Information about your use of the Services,
                  including access times, events or venues you attend, pages
                  viewed and other information about your interactions with us,
                  the Services and other users.
                </li>
                <li>
                  Device Information: Information about the computer or mobile
                  device you use to access our Services, which may include the
                  hardware model, operating system and version, browser type and
                  language, IP address, unique device identifiers and mobile
                  network information.
                </li>
                <li>
                  Location Information: Information about the location of your
                  device each time you attend an event or venue through Neon
                  Coat, access or use one of our mobile applications, or
                  otherwise consent to the collection of this information. We
                  collect this information in order to track your attendance at
                  our partners’ events and venues. For more details, please see
                  “Your Choices” below.
                </li>
                <li>
                  Information Collected by Cookies and Other Tracking
                  Technologies: Like most websites and applications, we and our
                  service providers use cookies, web beacons and other
                  technologies to collect information. Cookies are small data
                  files stored on your hard drive or in device memory that help
                  us, among other things, improve our Services and your
                  experience by seeing which areas and features of our Services
                  are popular. For more information about cookies, and how to
                  disable them, please see “Your Choices” below.
                </li>
                <li>
                  Log Information: We collect information in our log files when
                  you use the Services. This includes, among other things, your
                  web browser type and language, access times, pages viewed,
                  your IP address and the URL you visited before navigating to
                  our website.
                </li>
              </ul>
            </p>
            <p>
              <b>Information We Collect From Other Sources</b>
              <br /> We may also obtain information from other sources and
              combine that with information we collect through our Services. For
              example, we may collect information about you from third parties,
              including but not limited to identity verification services,
              mailing list providers and publicly available sources.
            </p>
            <p>
              USE OF INFORMATION
              <br /> We may use information about you for various purposes,
              including to:
              <ul>
                <li>
                  Provide, maintain and improve our Services, including to send
                  you availability requests and track your attendance at events
                  and venues;
                </li>
                <li>
                  Send you technical notices, updates, security alerts and
                  support and administrative messages;
                </li>
                <li>
                  Respond to your comments, questions and requests and provide
                  customer service;
                </li>
                <li>
                  Communicate with you about products, services, offers,
                  promotions, rewards, and events offered by Neon Coat and
                  others, and provide news and information we think will be of
                  interest to you;
                </li>
                <li>
                  Monitor and analyze trends, usage and activities in connection
                  with our Services;
                </li>
                <li>
                  Personalize and improve the Services and provide
                  advertisements, content or features that match user profiles
                  or interests;
                </li>
                <li>Process and deliver contest entries and rewards; </li>
                <li>
                  Link or combine with information we get from others to help
                  understand your needs and provide you with better service; and
                </li>
                <li>
                  Carry out any other purpose for which the information was
                  collected.
                </li>
              </ul>
            </p>
            <p>
              <b>Where Information Is Processed</b>
              <br /> Neon Coat is based in the United States and the information
              we collect is governed by U.S. law. By accessing or using the
              Services or otherwise providing information to us, you consent to
              the processing and transfer of information in and to the U.S. and
              other countries.
            </p>
            <p>
              <b>Sharing of Information</b>
              <br /> We may share information about you as follows or as
              otherwise described in this Privacy Policy:
              <ul>
                <li>
                  With vendors, venues, consultants and other service providers
                  who need access to such information to carry out work on our
                  behalf;
                </li>
                <li>
                  With third parties such as venues and event planners that we
                  partner with for their own services and marketing purposes,
                  unless you opt out of this type of sharing by emailing your
                  request to{" "}
                  <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>;
                </li>
                <li>
                  In response to a request for information if we reasonably
                  believe disclosure is in accordance with any applicable law,
                  regulation or legal process, or as otherwise required by any
                  applicable law, rule or regulation;
                </li>
                <li>
                  If we reasonably believe your actions are inconsistent with
                  our user agreements or policies, or to protect the rights,
                  property and safety of Neon Coat or others;
                </li>
                <li>
                  In connection with, or during negotiations of, any merger,
                  sale of company assets, financing or acquisition of all or a
                  portion of our business by another company;
                </li>
                <li>
                  Between and among Neon Coat, and its current and future
                  parents, affiliates, subsidiaries, and other companies under
                  common control and ownership; and • With your consent or at
                  your direction.
                </li>
              </ul>
            </p>
            <p>
              We may also share aggregated or de-identified information, which
              cannot reasonably be used to identify you.
            </p>
            <p>
              <b>Social Sharing Features</b>
              <br />
              The Services may offer social sharing features and other
              integrated tools (such as the Facebook “Like” button), which let
              you share actions you take on our Services with other media, and
              vice versa. Your use of such features enables the sharing of
              information with your friends or the public, depending on the
              settings you establish with the entity that provides the social
              sharing feature. For more information about the purpose and scope
              of data collection and processing in connection with social
              sharing features, please visit the privacy policies of the
              entities that provide these features.
            </p>
            <p>
              <b>Advertising and Analytics Services Provided by Others</b>
              <br /> We may allow others to provide analytics services and serve
              advertisements on our behalf across the internet and in
              applications. These entities may use cookies, web beacons, device
              identifiers and other technologies to collect information about
              your use of the Services and other websites and applications,
              including your IP address, web browser, information about your
              computer or mobile device, mobile network information, pages
              viewed, time spent on pages or in apps, links clicked and
              conversion information. This information may be used by Neon Coat
              and others to, among other things, analyze and track data,
              determine the popularity of certain content, deliver advertising
              and content targeted to your interests on our Services and other
              websites and better understand your online activity. For more
              information about interest-based ads, or to opt out of having your
              web browsing information used for behavioral advertising purposes,
              please visit{" "}
              <a href="https://youradchoices.com/">YourAdChoices.com</a>.
              You may also limit certain types of advertising by using the
              settings on your Apple and Android devices.
            </p>
            <p>
              We may also work with other websites or platforms (such as
              Facebook) to serve ads to you as part of a customized campaign,
              unless you notify us that you prefer not to have information about
              you used in this way. To opt out, email your request to{" "}
              <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.
            </p>
            <p>
              <b>Security</b>
              <br /> Neon Coat takes reasonable measures to help protect
              information about you from loss, theft, misuse and unauthorized
              access, disclosure, alteration and destruction.
            </p>
            <p>
              YOUR CHOICES
              <br />
              <b>Account Information</b>
              <br /> You may update, correct or delete information about you at
              any time by logging into your online account and accessing your
              member information page or emailing us at{" "}
              <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>. If you
              wish to delete your account, please email us at{" "}
              <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>, but note
              that we may retain certain information as required by law or for
              legitimate business purposes. We may also retain cached or
              archived copies of information about you for a certain period of
              time.
            </p>
            <p>
              <b>Location Information</b>
              <br /> When you first launch any of our mobile applications that
              collect location information, you will be asked to consent to the
              application’s collection of this information. We may require this
              location information in order to use our Services, so if you do
              not consent to this collection, you may be unable to use our
              Services. You may stop our collection of location information by
              ceasing to use our Services and following the standard uninstall
              process to remove all of our mobile applications from your device.
            </p>
            <p>
              <b>Cookies</b>
              <br /> Most web browsers are set to accept cookies by default. If
              you prefer, you can usually choose to set your browser to remove
              or reject browser cookies. Please note that if you choose to
              remove or reject cookies, this could affect the availability and
              functionality of our Services.
            </p>
            <p>
              <b>Promotional Communications</b>
              <br />
              You may opt out of receiving promotional emails or text messages
              from Neon Coat by following the instructions in those emails or
              text messages. If you opt out, we may still send you
              non-promotional communications, such as those about your account
              or our ongoing business relations.
            </p>
          </div>
        </div>
        <div className="col" />
        <br/>
        <br/>
        <br/>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
